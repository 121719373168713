<template>
  <div class="PShopDesc">
    <van-nav-bar
    title="商品详情"
    :fixed="true"
  />
    <div style="margin-top:50px">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" style="width:100%;height:200px"/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="desc">
      描述中心描述中心描述中心描述中心描述中心描述中心描述中心描述中心描述中心描述中心描述中心描述中心
    </div>
    <van-divider />
    <div style="margin: 10px;">
      商品详情
      <div style="margin: 10px;">
        <video src="https://gp-dev.cdn.bcebos.com/gp-dev/upload/file/source/0185b78de985735ca99a12a53c2b6d5c.mp4" controls="controls" style="width:100%;">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div style="margin: 10px;">
        <img v-lazy="'http://p4.music.126.net/YhuFJykXTHCJsbxqLToAqA==/109951166243559385.jpg'" style="width:100%;"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PShopDesc',
  data(){
    return {
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
        'http://p4.music.126.net/YhuFJykXTHCJsbxqLToAqA==/109951166243559385.jpg'
      ],
    }
  }
}
</script>
<style>
    .desc{
      margin: 15px;
      background-color: #e3e3e3;
    }
</style>
